import { render, staticRenderFns } from "./quizSidebar.vue?vue&type=template&id=dea7392a&scoped=true&"
import script from "./quizSidebar.vue?vue&type=script&lang=js&"
export * from "./quizSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dea7392a",
  null
  
)

export default component.exports